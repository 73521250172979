<template>
  <div
    class="vz-card"
    :class="{ [variant]: variant }"
    @click="onClick"
    @dblclick="onDbClick"
    @mousedown="onMousedown"
    @mouseup="onMouseup"
    @mousemove="onMousemove"
  >
    <slot/>
  </div>
</template>

<script setup lang="ts">
import {PropType} from 'vue';
import {IconVariants, UIKitVariants} from '~/types/variants';

const emit = defineEmits(['click', 'open'])
const props = defineProps({
  variant: {
    type: String as PropType<IconVariants | UIKitVariants>,
    default: ''
  }
})

let opening = false
let mouseDownPosition = [0, 0]

const emitOpen = (blank: boolean): void => {
  emit('open', blank)
}

const onClick = (e: MouseEvent): void => {
  emit('click', e)
}

const onMousedown = (e: MouseEvent): void => {
  if (e.composedPath().find(el => (el as any).className?.includes('vz-button'))) {
    return
  }
  mouseDownPosition = [e.x, e.y]
  if (e.button === 0 || e.button === 1) {
    if (e.button === 1) {
      e.preventDefault()
    }
    opening = true
  }
}

const onMousemove = (): void => {
  // opening = false
}
const onMouseup = (e: MouseEvent): void => {
  setTimeout(() => {
    if (opening) {
      const offset = [Math.abs(mouseDownPosition[0] - e.x), Math.abs(mouseDownPosition[1] - e.y)]
      if (offset.find(v => v > 5)) {
        opening = false
        return
      }

      if (e.button === 0) {
        emitOpen(false)
      } else if (e.button === 1) {
        emitOpen(true)
      }
    }
  }, 300)
}

function onDbClick() {
  opening = false
}
</script>